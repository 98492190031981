import React from "react";
import Header from "../../components/Header"
import { Footer } from "../../components/Footer";
import { HeroSection } from "./HeroSection";
import { Description } from "./Description";


export const FamilyOffice = () => {
  return <>
  <Header page="family-office"/>
  <HeroSection/>
  <Description/>
  <Footer/>

  </>
};
