export enum AuthRegisterActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/register/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/register/API_RESPONSE_ERROR",

  REGISTER_USER = "@@auth/register/REGISTER_USER",
  LOGIN = "@@auth/login/LOGIN",
  LOGGED_USER = "@@auth/login/LOGGED_USER",
  GOOGLE_AUTH = "@@auth/login/LINKEDIN_AUTH",
  LINKEDIN_AUTH = "@@auth/login/LINKEDIN_AUTH",
  SIGN_UP = "@@auth/register/SIGN_UP",
  SIGN_UP_DATA = "@@auth/register/SIGN_UP_DATA",
  GOOGLE_DATA = "@@auth/register/GOOGLE_DATA",
  LINKEDIN_ACCESS_TOKEN = "@@auth/register/LINKEDIN_ACCESS_TOKEN",
  LINKEDIN_USER_DATA = "@@auth/register/LINKEDIN_USER_DATA",
  IS_USER_LOGGED = "@@auth/register/IS_USER_LOGGED",
  LOGOUT_USER = "@@auth/register/LOGOUT_USER",
  RESEND_EMAIL = "@@auth/register/RESEND_EMAIL",
  SIGN_UP_INVESTOR = "@@auth/register/SIGN_UP_INVESTOR",
  SIGN_UP_STARTUP = "@@auth/register/SIGN_UP_STARTUP",
  NEWSLETTER_SUBSCRIBE = "@@auth/register/NEWSLETTER_SUBSCRIBE",
  CONTACT_US = "@@auth/register/CONTACT_US",
  REQUEST_ANALYSIS = "@@auth/register/REQUEST_ANALYSIS",
  CREATE_COMPANY_WITH_FILES = "@@auth/register/CREATE_COMPANY_WITH_FILES",
  CREATE_COMPANY = "@@auth/register/CREATE_COMPANY",
  GET_COMPANY = "@@auth/register/GET_COMPANY",
  CREATE_INDIVIDUAL_INVESTOR = "@@auth/register/CREATE_INDIVIDUAL_INVESTOR",
  GET_INDIVIDUAL_INVESTOR = "@@auth/register/GET_INDIVIDUAL_INVESTOR",
  CREATE_COMPANY_INVESTOR = "@@auth/register/CREATE_COMPANY_INVESTOR",
  GET_COMPANY_INVESTOR = "@@auth/register/GET_COMPANY_INVESTOR",
  RESET_PASSWORD = "@@auth/register/RESET_PASSWORD",
  GET_RESET_PASSWORD = "@@auth/register/GET_RESET_PASSWORD",
  SEND_RESET_PASSWORD_EMAIL = "@@auth/register/SEND_RESET_PASSWORD_EMAIL",
  DELETE_FILE = "@@auth/register/DELETE_FILE",
  GET_INDUSTRIES = "@@auth/register/GET_INDUSTRIES",
  SET_PREFERENCES = "@@auth/register/SET_PREFERENCES"
}

export type IUserLogged = {
  category:any,
  email:any,
  full_name:any,
  lead_type:any,
  max_fund:any,
  min_fund: any,
  phone:any
}
export type IUserCallback = {
 profileObj:{
  email:string;
  familyName: string;
  givenName:string;
  imageUrl:string;
  name:string;
 };
 tokenId:string;
 accessToken:string;
 googleId:string;
}

export type IUser = {
  email:string;
  password:string;
}

export type ISignUp = {
  first_name: string;
  full_name : string;
  email :string;
  password: string;
  phone : string;
  category :string;
  fund: string;
  optedin_newsletter:string;
}

export type ILinkedInAccessToken = {
  access_token:string;
  expires_in:number;
}
export interface AuthRegisterState {
  registrationError: any;
  message: string;
  isNewsletterSent: boolean;
  isContactSent:boolean;
}
