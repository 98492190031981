import { PagesActionTypes, PagesState } from "./types";

export const INIT_STATE: PagesState = {
  activeState:"home",
  activeAboutPage: "about",
  activeCareersPage: "careers",
  loader: false,
  loader2: false,
};

const Pages = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case PagesActionTypes.SET_ACTIVE_STATE: {
      return {
        ...state,
        activeState:action.payload.activeState
      };
    }
    case PagesActionTypes.ACTIVE_ABOUT_PAGE: {
      return {
        ...state,
        activeAboutPage:action.payload.activeAboutPage
      };
    }
    case PagesActionTypes.ACTIVE_CAREERS_PAGE: {
      return {
        ...state,
        activeCareersPage:action.payload.activeCareersPage
      };
    }
    case PagesActionTypes.LOADER: {
      return {
        ...state,
        loader:action.payload.loader
      };
    }
    case PagesActionTypes.LOADER2: {
      return {
        ...state,
        loader2:action.payload.loader2
      };
    }

    default:
      return { ...state };
  }
};

export default Pages;
