import React from "react";
import Header from "../../components/Header";
import { Description } from "./Description";
import { HeroSection } from "./HeroSection";
import { InvestmentSection } from "./InvestmentSection";
import { VideoSection } from "./VideoSection";
import { Scheduler } from "./Scheduler";
import { Newsletter } from "./Newsletter";
import { Footer } from "../../components/Footer";

export const Home = () => {
  return <>
  <Header page="home"/>
  <HeroSection/>
  <Description/>
  <InvestmentSection/>
  <VideoSection/>
  <Scheduler/>
  <Newsletter/>
  <Footer/>
  </>
};
