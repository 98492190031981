import APIClient from "./api";
import * as url from "./urls";

const api = new APIClient();


const unsubscribe = (params:any) => {
    return api.create(`/newsletter_unsubscribe`, params.email)
  }

  export{
    unsubscribe
  }