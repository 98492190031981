import APIClient from "./api";
import * as url from "./urls";

const api = new APIClient();



const getMessages = (params:any) => {
    return api.getWithToken(`${url.MESSAGES}/${params.id}`)
  }
  
const sendMessages = (params:any) => {
    return api.createWithToken(`${url.MESSAGES}${url.SEND}/${params.id}`,
     params.data
    )
  }
  


  export{
    getMessages,
    sendMessages
  }