import React from 'react';
import Header from '../../components/Header';
import { Footer } from '../../components/Footer';
import { HeroSection } from './HeroSection';
import { Newsletter } from '../Home/Newsletter';
import { Description } from './Description';

export const Careers = () => {
    return(<>
      <Header page="careers"/>
  <HeroSection/>
  <Description/>
  <Newsletter/>
  <Footer/>
    </>)
}