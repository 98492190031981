import React from 'react';

export const InvestmentSection = () => {
  return (
    <div className="px-4 pb-12 mx-auto container w-12/12 bg-white">
    
      <div className='flex flex-col'>
        <div className='py-6'>
          <span className='font-primary font-size-30 font-bold'>
            Increase your deal flow Gain speed to market
          </span>
        </div>
        <div className='py-4'>
        <span className='font-primary font-size-20 font-thin'>
          Konzortia Capital provides <span className='font-bold'>FREE</span>{' '}
          access to its Konzortia Hub platform and with our Paraforge acquisition,
          our Intellectual Property now sources deals and matches projects to
          investors through Artificial Intelligence (AI). Our Machine Learning
          enhances matching capabilities analyzing hundred of thousands of data
          points every day.{' '}
        </span>
        </div>
        <div className='py-8'>
        <span className='font-primary font-size-30 font-bold py-6'>
          DISCOVER KONZORTIA CAPITAL
        </span>
        </div>
        <span className='font-primary font-size-30 font-thin'>
          <div className='flex flex-col'>
          <div className='flex flex-col center-div lg:flex-row md:flex-row '>
            <div className='px-12 py-12'>
              <img src='/teamwork1.svg' />
            </div>
            <div className='px-12 py-12'>
              <img src='/teamwork2.svg' />
            </div>
            <div className='px-12 py-12'>
              <img src='/teamwork3.svg' />
            </div>
            <div className='px-12 py-12'>
              <img src='/teamwork4.svg' />
            </div>
          
          </div>
          <div className='flex py-12 flex-col center-div lg:flex-row md:flex-row '>
            <div className='px-12 py-12'>
              <img src='/konzortiahub.svg' />
            </div>
            <div className='px-12 py-12'>
              <img src='/konzortialedger.svg' />
            </div>
            <div className='px-12 py-12'>
              <img src='/konzortiamarket.svg' />
            </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};
