import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormValues from '../../components/FormValues';
import { useRedux } from '../../hooks';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { setNewsletter } from '../../redux/actions';
export const Newsletter = () => {

  const { dispatch, useAppSelector } = useRedux();
  const {
    isNewsletterSent,
    isModalOpen,
    modalOpen
  } = useAppSelector((state) => ({
    isNewsletterSent:state.Auth.isNewsletterSent,
    isModalOpen: state.Modal.modalOpen,
    modalOpen:state.Modal.whichModal

  }));
  const resolver = yupResolver(
    yup.object().shape({
      email: yup.string().required("Required field."),
    })
  );

  const defaultValues: any = {
    email: "",
    // countries:"United States"
  };

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmitForm = (values:any) => {
dispatch(setNewsletter({email:values.email}))
  }

    return (<>

 <div className=" bg-white flex center-div text-white mt-2">
      <div className="justify-between items-center sm:px-12 px-4   py-4">
        <div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row center-div">
    <div className="lg:each-wrap lg:w-3/12 relative p-10">
        {/* <Logo/> */}
        <>
        <div className='m-3 text-left flex flex-col'>
    <span className="font-primary color-black font-bold font-size-24">Join the Blast-Off</span>
      <span className='font-primary color-black '>Receive our Newsletter with takes on the investment market.</span>
    </div>
</>
    </div>
    <div className="lg:each-wrap lg:w-3/12 center-div flex lg:flex">
    {/* <Player
      src={rocketAnimation}
      className="player"
      loop
      autoplay
      style={{height: '100%', width: '100%'}}
      /> */}
    <img
            src="/rocketAnimation.gif"
            className="rocket-gif"
          />

       </div>
       <div className="lg:each-wrap flex flex-col lg:w-3/12 center-div  lg:flex">
        {
          isNewsletterSent ?
          <div className='flex flex-row'>
          <form
                   onSubmit={handleSubmit(onSubmitForm)}
                   className="position-relative mt-6"
                 >
          <div className=" flex center-div">
                      <span className='font-primary color-black font-size-24'>Subscribed!</span>
                     <div className='p-2'>
                     {/* <button className='btn-black '>Get newsletter</button> */}
                     </div>
                     </div>
                     </form>
                     </div>
          :
          <div className='flex flex-row'>
          <form
                   onSubmit={handleSubmit(onSubmitForm)}
                   className="position-relative mt-6"
                 >
          <div className=" flex flex-col">
                       <FormValues
                         type="email"
                         name="email"
                         control={control}
                         errors={errors}
                         register={register}
                         placeholder="Email Address"
                         className="form-control font-primary font-size-16 "
                         hidePasswordButton={true}
                       />
                     <div className='p-2'>
                     <button className='btn-primary w-100' type="submit">Get newsletter</button>
                     </div>
                     </div>
                     </form>
                     </div>
        }

                 
                  <div className='m-3 text-left '>
                  {/* <span className='font-label-black font-size-16'>Opt In Copy & Privacy Policy</span> */}
                  </div>
       </div>
     
    </div>
 
       

       
    </div>   
    </>
    )
}