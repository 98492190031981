export enum PagesActionTypes {
  SET_ACTIVE_PAGE = "@@pages/SET_ACTIVE_PAGE",
  SET_ACTIVE_TIMELINE_STEP = "@@pages/SET_ACTIVE_TIMELINE_STEP",
  SET_ACTIVE_SUBSTEP = "@@pages/SET_ACTIVE_SUBSTEP",
  SET_ACTIVE_STATE = "@@pages/SET_ACTIVE_STATE",
  SET_ACTIVE_SIGNUP_TIMELINE_STEP = "@@pages/SET_ACTIVE_SIGNUP_TIMELINE_STEP",
  SET_INVESTOR_TYPE = "@@pages/SET_INVESTOR_TYPE",
  SIGN_UP_DATA = "@@pages/SIGN_UP_DATA",
  OPEN_MODAL = "@@pages/OPEN_MODAL",
  MODAL_OPEN = "@@pages/MODAL_OPEN",
  LOADER = "@@pages/LOADER",
  LOADER2 = "@@pages/LOADER2",
  CONTACTS_HUBSPOT="@@pages/CONTACTS_HUBSPOT",
  ACTIVE_ABOUT_PAGE = "pages/ACTIVE_ABOUT_PAGE",
  ACTIVE_CAREERS_PAGE = "pages/ACTIVE_CAREERS_PAGE"
}

export type IData = {
  name: string | null;
  email: string | null;
  password: string | null;
};

export interface PagesState {
  activeState:
    | "home"
    | "about"
    | "investment"
    | "careers"
    | "news"
    | "contact",
  activeAboutPage: "mission" | "members" | "investment" | "about"  
 
  activeCareersPage:   "careers" | "konzortians"
  | "workEnviroment"
  | "diversity"
  | "traction"
  | "roadmap",
  loader: boolean,
  loader2: boolean
}
