import { combineReducers } from "redux";

import Auth from "./auth/register/reducer";
import Modal from "./modals/reducer";
import Pages from "./pages/reducer"

export default combineReducers({
  Auth,
  Pages,
  Modal,
});
