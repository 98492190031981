import { AuthRegisterActionTypes } from "./types";

// common success
export const authRegisterApiResponseSuccess = (
  actionType: string,
  data: any
) => ({
  type: AuthRegisterActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const authRegisterApiResponseError = (
  actionType: string,
  error: string
) => ({
  type: AuthRegisterActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const userRegister = (user: any) => {
  return {
    type: AuthRegisterActionTypes.REGISTER_USER,
    payload: { user },
  }; 
};

export const login = (data:object) => {
  return {
    type: AuthRegisterActionTypes.LOGIN,
    payload: { data },
  };
};

export const setLoggedUser = (data:object) => {
  return {
    type: AuthRegisterActionTypes.LOGGED_USER,
    payload: { data },
  };
};

export const linkedinAuth = (data:object) => {
  return {
    type: AuthRegisterActionTypes.LINKEDIN_AUTH,
    payload: { data },
  };
};

export const signUp = (data:object) => {
  return {
    type: AuthRegisterActionTypes.SIGN_UP,
    payload: { data },
  };
};

export const signUpInvestor = (data:object) => {
  return {
    type: AuthRegisterActionTypes.SIGN_UP_INVESTOR,
    payload: { data },
  };
};

export const signUpStartup = (data:object) => {
  return {
    type: AuthRegisterActionTypes.SIGN_UP_STARTUP,
    payload: { data },
  };
};

export const googleSignUp = (data:object) => {
  return {
    type: AuthRegisterActionTypes.GOOGLE_AUTH,
    payload: { data },
  };
};

export const linkedinSignUp = (data:object) => {
  return {
    type: AuthRegisterActionTypes.LINKEDIN_AUTH,
    payload: { data },
  };
};

export const signUpData = (data:object) => {
  return {
    type: AuthRegisterActionTypes.SIGN_UP_DATA,
    payload: { data },
  };
};

export const setGoogleData = (data:object) => {
  return {
    type: AuthRegisterActionTypes.GOOGLE_DATA,
    payload: { data },
  };
};

export const setLinkedinAccessToken = (data:object) => {
  return {
    type: AuthRegisterActionTypes.LINKEDIN_ACCESS_TOKEN,
    payload: { data },
  };
};

export const setIsUserLogged = (data:boolean) => {
  return {
    type: AuthRegisterActionTypes.IS_USER_LOGGED,
    payload: { data },
  };
};

export const logoutUser = () => {
  return {
    type: AuthRegisterActionTypes.LOGOUT_USER,
  };
};

export const resendEmail = (email:string) => {
  return {
    type:AuthRegisterActionTypes.RESEND_EMAIL,
    payload: { email },
  }
}

export const setNewsletter = (data:object) => {
  return {
    type:AuthRegisterActionTypes.NEWSLETTER_SUBSCRIBE,
    payload: { data },
  }
}

export const setContactUs = (data:object) => {
  return {
    type:AuthRegisterActionTypes.CONTACT_US,
    payload: { data },
  }
}

export const setRequest = (data:object) => {
  return {
    type:AuthRegisterActionTypes.REQUEST_ANALYSIS,
    payload: {data}
  }
}

export const createWithFilesCompany = (data:object,id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.CREATE_COMPANY_WITH_FILES,
    payload: { data, id, step },
  }
}

export const createCompany = (data:object,id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.CREATE_COMPANY,
    payload: { data, id, step },
  }
}

export const getCompany = (id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.GET_COMPANY,
    payload: { id, step },
  }
}


export const createIndividualInvestor = (data:object,id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.CREATE_INDIVIDUAL_INVESTOR,
    payload: { data, id, step },
  }
}

export const getIndividualInvestor = (id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.GET_INDIVIDUAL_INVESTOR,
    payload: { id, step },
  }
}

export const createCompanyInvestor = (data:object,id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.CREATE_COMPANY_INVESTOR,
    payload: { data, id, step },
  }
}

export const getCompanyInvestor = (id:number,step:number) => {
  return {
    type:AuthRegisterActionTypes.GET_COMPANY_INVESTOR,
    payload: { id, step },
  }
}
export const setResetPassword = (data:object) => {
  return {
    type:AuthRegisterActionTypes.RESET_PASSWORD,
    payload: { data },
  }
}
export const getResetPassword = (email:string) => {
  return {
    type:AuthRegisterActionTypes.GET_RESET_PASSWORD,
    payload: { email },
  }
}
export const setResetPasswordEmail = (data:boolean) => {
  return {
    type:AuthRegisterActionTypes.SEND_RESET_PASSWORD_EMAIL,
    payload: { data },
  }
}

export const deleteFileFromCompany = (id:number,type:string) => {
  return {
    type:AuthRegisterActionTypes.DELETE_FILE,
    payload: { id,type },
  }
}
export const getIndustries = (id:number) => {
  return {
    type:AuthRegisterActionTypes.GET_INDUSTRIES,
    payload: { id },
  }
}
export const createPreferences = (id:number, data:object) => {
  return {
    type:AuthRegisterActionTypes.SET_PREFERENCES,
    payload: { id, data},
  }
}