import { setCookie } from "nookies";
import { AuthRegisterActionTypes, AuthRegisterState } from "./types";

export const INIT_STATE: AuthRegisterState = {
  registrationError: null,
  message: "",
  isNewsletterSent:false,
  isContactSent:false
};

const Register = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case AuthRegisterActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthRegisterActionTypes.REGISTER_USER:
          return {
            ...state,
            loading: false,
            user: action.payload.data,
            registrationError: null,
            isUserRegistered: true,
          };
        case AuthRegisterActionTypes.LOGIN:
          // setCookie(
          //   null,
          //   "@InvestHUB.loggedUser",
          //   JSON.stringify(action.payload.data.data),
          //   {
          //     maxAge: 60 * 60 * 24,
          //     domain: myDomain,
          //     path: "/",
          //   }
          // );
          window.localStorage.setItem("@InvestHUB.access_token",action.payload.data.data.access_token);
          window.localStorage.setItem("@InvestHUB.refresh_token",action.payload.data.data.refresh_token);

          return {
            ...state,
            loading: false,
            user: action.payload.data.data,
            loggedUser: true,
            registrationError: null,
            isUserRegistered: true,
          };
        case AuthRegisterActionTypes.RESET_PASSWORD:
          return {
            ...state,
            isPasswordReset:true
          };
        case AuthRegisterActionTypes.GET_RESET_PASSWORD:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.LOGGED_USER:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.SIGN_UP:
          return {
            ...state,
            registerUser: true,
          };
        case AuthRegisterActionTypes.SIGN_UP_INVESTOR:
          return {
            ...state,
            isInvestorRegister: true,
          };
        case AuthRegisterActionTypes.SIGN_UP_STARTUP:
          return {
            ...state,
            isStartupRegister: true,
          };
        case AuthRegisterActionTypes.GOOGLE_AUTH:
          return {
            ...state,
            loggedUser: true,
          };
        case AuthRegisterActionTypes.LINKEDIN_AUTH:
          return {
            ...state,
            loggedUser: true,
          };
        case AuthRegisterActionTypes.LINKEDIN_ACCESS_TOKEN:
          // setCookie(
          //   null,
          //   "@InvestHUB.loggedUser",
          //   JSON.stringify({
          //     name: action.payload.data.data.me.firstName.localized.en_US,
          //     email: action.payload.data.data.email.elements[0],
          //   }),
          //   {
          //     maxAge: 60 * 60 * 24,
          //     domain: myDomain,
          //     path: "/",
          //   }
          // );
          return {
            ...state,
            linkedinAccessToken: action.payload.data.data,
            linkedinEmail: action.payload.data.data.email.elements[0],
            linkedinName: action.payload.data.data.me.firstName.localized.en_US,
            loggedUser: true,
          };
        case AuthRegisterActionTypes.LINKEDIN_USER_DATA:
          return {
            ...state,
            linkedinUserData: action.payload.data,
          };
        case AuthRegisterActionTypes.LOGOUT_USER:
          return {
            ...state,
            loggedUser: false,
          };
        case AuthRegisterActionTypes.RESEND_EMAIL:
          return {
            ...state,
            isEmailResend: true,
          };
        case AuthRegisterActionTypes.NEWSLETTER_SUBSCRIBE:
          return {
            ...state,
            isNewsletterSent:true
          };
        case AuthRegisterActionTypes.CONTACT_US:
          return {
            ...state,
            isContactSent: true,
          };
        case AuthRegisterActionTypes.CREATE_COMPANY_WITH_FILES:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.CREATE_COMPANY:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.GET_COMPANY:
          return {
            ...state,
            companyData: action.payload.data.data,
          };
        case AuthRegisterActionTypes.CREATE_INDIVIDUAL_INVESTOR:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.GET_INDIVIDUAL_INVESTOR:
          return {
            ...state,
            companyData: action.payload.data.data,
          };
        case AuthRegisterActionTypes.GET_COMPANY_INVESTOR:
          return {
            ...state,
            companyData: action.payload.data.data,
          };
        case AuthRegisterActionTypes.DELETE_FILE:
          return {
            ...state,
            isFileDeleted: true,
          };
        case AuthRegisterActionTypes.CREATE_COMPANY_INVESTOR:
          return {
            ...state,
          };
        case AuthRegisterActionTypes.GET_INDUSTRIES:
          return {
            ...state,
            industries: action.payload.data.data.all_industries
          };
        default:
          return { ...state };
      }

    case AuthRegisterActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthRegisterActionTypes.REGISTER_USER:
          return {
            ...state,
            loading: false,
            registrationError: action.payload.error,
            isUserRegistered: false,
          };
        case AuthRegisterActionTypes.LOGIN:
          return {
            ...state,
            loading: false,
            login: action.payload.error,
            isUserRegistered: false,
          };
        case AuthRegisterActionTypes.SIGN_UP:
          return {
            ...state,
            signUpError: action.payload.error,
          };
        case AuthRegisterActionTypes.GOOGLE_AUTH:
          return {
            ...state,
            googleSignUpError: action.payload.error,
          };
        case AuthRegisterActionTypes.LINKEDIN_AUTH:
          return {
            ...state,
            linkedinSignUpError: action.payload.error,
          };
    
        case AuthRegisterActionTypes.LINKEDIN_ACCESS_TOKEN:
          return {
            ...state,
            linkedinError: action.payload.error,
          };
        case AuthRegisterActionTypes.LINKEDIN_USER_DATA:
          return {
            ...state,
            linkedinUserDataError: action.payload.error,
          };
        case AuthRegisterActionTypes.RESEND_EMAIL:
          return {
            ...state,
          };
          case AuthRegisterActionTypes.CREATE_COMPANY_WITH_FILES:
            return {
              ...state,
            };
          case AuthRegisterActionTypes.CREATE_COMPANY:
            return {
              ...state,
            };
          case AuthRegisterActionTypes.GET_COMPANY:
            return {
              ...state,
            };
        default:
          return { ...state };
      }

    case AuthRegisterActionTypes.REGISTER_USER: {
      return {
        ...state,
        loading: true,
        isUserRegistered: false,
      };
    }
    case AuthRegisterActionTypes.SIGN_UP_DATA:
      return {
        ...state,
        signUpData: action.payload.data,
      };
    case AuthRegisterActionTypes.GOOGLE_DATA:
      return {
        ...state,
        loggedUser: true,
        googleData: action.payload.data,
      };
    case AuthRegisterActionTypes.IS_USER_LOGGED:
      return {
        ...state,
        loggedUser: action.payload.data,
      };
    case AuthRegisterActionTypes.SEND_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        sendResetPasswordEmail: action.payload.data,
      };

    default:
      return { ...state };
  }
};

export default Register;
