import React from 'react';

export const VideoSection = () => {
  return (
    <div className='bg-gray'>
      <div className='px-4 pb-12 mx-auto container w-12/12 '>
        <div className='py-6'>
        <span className='font-primary left-div uppercase font-size-30 font-bold'>
          Konzortia Capital Stories
        </span>
        </div>
        <div className='flex py-2 flex-col md:flex-row lg:flex-row'>
          <div className='flex px-4 flex-col'>
            <img src='/walterGomezVideo.svg' />
            <span className='font-primary py-2 font-size-12 font-bold'>
              Walter Gomez, CEO & Founder, presents to Private Capital Markets
              Conference.
            </span>
          </div>
          <div className='flex px-4 flex-col'>
            <img src='/konzortiaCapitalVideo.svg' />
            <span className='font-primary py-2 font-size-12 font-bold'>
            Konzortia Capital announces launch of Pre-Series A Round to raise $3.8 MM.
            </span>
          </div>
          <div className='flex px-4 flex-col'>
            <img src='/konzortiaCapitalVideo2.svg' />
            <span className='font-primary py-2 font-size-12 font-bold'>
            Paraforge acquired by Konzortia Capital for $5.3 MM.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
