import { setCookie } from "nookies";
import { ModalActionTypes, ModalState } from "./types";

export const INIT_STATE: ModalState = {
  modalOpen: false,
  whichModal: null,
};

const Modal = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ModalActionTypes.WHICH_MODAL:
      return {
        ...state,
        whichModal: action.payload.whichModal,
      };
    case ModalActionTypes.IS_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
      };
    default:
      return { ...state };
  }
};

export default Modal;
