import React from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineCheckCircle,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { ContactUs } from '../Home/ContactUs';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import { useRedux } from '../../hooks';
import { setActiveCareersPage } from '../../redux/actions';

export const Description = () => {
  const { dispatch, useAppSelector } = useRedux();
  const {activeCareersPage
  } = useAppSelector((state) => ({
    activeCareersPage: state.Pages.activeCareersPage
    }));
  return (
    <>
      <div className='px-4  mx-auto container w-12/12 bg-white '>
            {
              activeCareersPage === "careers" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
  Ignite your Careers at Konzortia    </span>
    <span className='font-primary font-size-16 text-black '>
    Explore careers at Konzortia Capital and Blastoff with us towards new horizons.

We are building the foundation to a new world in the Private Capital Markets as we shift paradigms and create innovative solutions that create efficiencies in operations for Investors and accelerate Founders through the stages of capital raising. Discover a brave new world in Artificial Intelligence and Machine Learning, as well as Blockchain settlements that are disrupting the sector.
<p className='py-3'>
Become part of a thriving new initiative with a serviceable market of $585 B. Create a legacy for Konzortia and yourself with the freedom to innovate and make an impact on the growth and results of this company. Gain valuable experience with a Fintech SaaS and prepare yourself for new adventures to come.
</p>
    </span>
    <div className='lg:py-4 flex flex-col center-div'>
    <span className="font-primary text-4xl font-bold color-primary uppercase"> JOIN OUR TEAM!</span>
    <div className="py-12"><span className="font-primary text-xl font-thin color-black "> We are always looking for new talent who want to become part of our journey! If you think you have what it takes send your CV to <span className="color-primary font-bold">careers@konzortiacapital.com</span></span>.</div>
  <img src="/careers.png"/>
  </div>
  
  </div>
  <div className='py-12 w-12/12 lg:w-4/12'>
    <div className='flex flex-col h-100 '>
      <div className='flex center-div flex-col'>
        <div className='pt-3 '>
        <YoutubeEmbed embedId='p26S7yqzv-g'/>
        </div>
  
      </div>
      <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
            {
              activeCareersPage === "konzortians" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
    Konzortians
    </span>
    <span className='font-primary font-size-16 text-black '>
    KONZORTIANS are an extraordinary team of visionary, multicultural risk-takers and mavens ready to conquer the galaxy! We are known for our courage and spirit of adventure… always going above and beyond while exploring new frontiers. Through collaboration, we pave the way towards progress engaged, making strong contributions to achieve new milestones, while facilitating great opportunities for both Founders and Funders. Our integrity defines us! We are steadfast, committed and accountable to our mission steering with precision to reach our destiny!   </span>
  
  </div>
  <div className='py-12 w-12/12 lg:w-4/12'>
    <div className='flex flex-col h-100'>
     
    <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
            {
              activeCareersPage === "workEnviroment" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
  Konzortia’ Mission Control – Work Environment
    </span>
    <span className='font-primary font-size-16 text-black '>
    Since we are a global entity, our ship has many ports! Whether we are working from India, Pakistan, Syria, Brazil, Colombia, Costa Rica, Europe or the United States, among many other countries… we are committed and accountable to each other on a daily basis. We leverage communications platforms to stay close and in touch sharing our progress. Jira is our compass as we leverage this tool to manage our projects and tasks to fruition. WhatsApp is our tethered line to the Flag Ship and to each other.
<p className='py-3'>
We are flexible with our work environment and yet accountable to each other, our commitments and dedicated contractual hours so that we can accomplish our mission. We are supportive of each other and are encouraged to create and evolve our jobs through innovation. 
</p></span>
  
  </div>
  <div className='py-12 w-12/12 lg:w-4/12'>
    <div className='flex flex-col h-100'>
     
    <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
            {
              activeCareersPage === "diversity" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
  Diversity & Inclusiveness    </span>
    <span className='font-primary font-size-16 text-black '>
    As Mahatma Gandhi once declared, “Our ability to reach unity in diversity will be the beauty and the test of our civilization”. At Konzortia we bring our crew and the world together… TOGETHER we are as one!
    <p className='py-3'>

Konzortians of all different makes and models navigate in the same direction, bringing their cultural nuances, perspectives and knowledge to contribute positively to our success in an interconnected world.
</p>
Konzortia is an equal opportunity employer appreciating the individual strengths, contributions, and potential from each Konzortian regardless of their age, religion, race or sexual identity.
</span>
  
  </div>
  <div className='py-12 lg:w-4/12 w-12/12'>
    <div className='flex flex-col h-100'>
     
    <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
            {
              activeCareersPage === "roadmap" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
 Road Map    </span>
    <span className='font-primary font-size-16 text-black '>
    Konzortia Capital is constantly evolving as a company and with its platforms.


 <p className='py-3'>

 We are in a unique position at this time to shift paradigms. We are well along and making progress on our road map with the Konzortia Hub platform almost ready to be launched, and our acquisition of Paraforge and proprietary AI / ML technology integrated, we soon start to focus on our Distributed Ledger platform and OTC secondary market liquidity settlement solutions for Private Capital Markets.   </p>
</span>
  
  </div>
  <div className='py-12 lg:w-4/12 w-12/12'>
    <div className='flex flex-col h-100'>
     
    <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
            {
              activeCareersPage === "traction" &&
              <div className='flex flex-col lg:flex-row '>
              <div className='lg:w-8/12 
               w-12/12 leading-loose py-12 px-12 flex flex-col content-left justify-left text-left'>

  <span className='font-primary font-size-22 text-black font-bold py-4'>
  Our Traction    </span>
  <div className='flex flex-col'>
  <div className='flex flex-row'>
    <div className='color-primary left-div py-2 px-2'>
          <AiOutlineCheckCircle/>
          </div>
          <span className='font-primary font-size-16 text-black '>
          Strong Management Team          
          </span>
        </div>
  <div className='flex flex-row'>
    <div className='color-primary left-div py-2 px-2'>
          <AiOutlineCheckCircle/>
          </div>
          <span className='font-primary font-size-16 text-black '>
          Pre-Seed & Seed Capital Achievement          </span>
        </div>
  <div className='flex flex-row'>
    <div className='color-primary left-div py-2 px-2'>
          <AiOutlineCheckCircle/>
          </div>
          <span className='font-primary font-size-16 text-black '>
          Acquisition of M&A of Paraforge Valued at $5.3MM         </span>
        </div>
  <div className='flex flex-row'>
    <div className='color-primary left-div py-2 px-2'>
          <AiOutlineCheckCircle/>
          </div>
          <span className='font-primary font-size-16 text-black '>
          Last 2 years of operations Paraforge generated $5.2MM+ in revenues        </span></div>
  <div className='flex flex-row'>
    <div className='color-primary left-div py-2 px-2'>
          <AiOutlineCheckCircle/>
          </div>
          <span className='font-primary font-size-16 text-black '>
          Gross Profit Margin of 77.48%
          </span></div>
        </div>
        </div>
  
  <div className='py-12 lg:w-4/12 w-12/12'>
    <div className='flex flex-col h-100'>
     
    <div className=' py-4'>
      <div className='bg-gray rounded py-4'>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("konzortians"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Konzortians
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("workEnviroment"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Working at Konzortia
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("diversity"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Diversity & Inclusiveness                            </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("traction"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Our Traction                         
          </span>
        </div>
        <div className='py-2 px-6 left-div flex flex-row pointer' onClick={() => dispatch(setActiveCareersPage("roadmap"))}>
          <img className='px-2' src="/linkIcon.svg"/>
          <span className='font-primary color-black '>
          Road Map                        
          </span>
        </div>
      </div>
      </div>
      <div className='flex  py-10 px-2 w-12/12 '>
        <div className='social-media-card w-100 '>
          <div className='flex flex-row w-12/12 h-100 center-div'>
            <span className='font-secondary-italic text-white font-size-24'>
              Follow us!
            </span>
            <div className='px-2 color-white'>
              <AiOutlineTwitter size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillFacebook size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiFillLinkedin size={20} />
            </div>
            <div className='px-2 color-white'>
              <AiOutlineInstagram size={20} />
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
            }
          
                  
      </div>
    </>
  );
};
