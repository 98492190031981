import { ModalActionTypes } from "./types";

export const setWhichModal = (whichModal: string) => ({
  type: ModalActionTypes.WHICH_MODAL,
  payload: { whichModal },
});

export const setModalOpen = (modalOpen: boolean) => ({
  type: ModalActionTypes.IS_MODAL_OPEN,
  payload: { modalOpen },
});
