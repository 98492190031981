import axios from "axios";
import { parseCookies } from "nookies";
import { useEffect, useState } from "react";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// axios.defaults.headers.post["Content-Type"] = "application/json";
// axios.defaults.headers.common['Authorization'] = `Bearer ${user && user.access_token}`;
// const access_token = window.localStorage.getItem('@InvestHUB.access_token')
// const refresh_token = window.localStorage.getItem('@InvestHUB.refresh_token')
// axios.interceptors.response.use(
//   (response) => {
//     console.log("INTERCEPTOR RESPONSE: ", response);
//     return response.data.data ? response.data.data : response;
//   },
//   async function (error) {
//     const originalRequest = error.response;
//     const retryRequest = error.config;
//     console.log("INTERCEPTOR REQUEST ERROR: ", originalRequest);
//     if (
//       error.response.status === 401 &&
//       user && user.type === "investor"
//     ) {
//       retryRequest._retry = true;
//         const {  "@InvestHUB.loggedUser": cookieData } = parseCookies();
//         const user = cookieData && JSON.parse(cookieData);
//         const config = {
//           headers: {
//             'authorization': `Bearer ${user.access_token}`,
//             // 'Accept' : 'application/json',
//             //   'Content-Type': 'application/json'
//           }}
//       // return await axios
//       //   .get("/refresh/investor", config)
//       //   .then((resp) => {
//       //     window.localStorage.setItem("@InvestHUB.access_token",resp.data.access_token);
//       //     window.localStorage.setItem("@InvestHUB.refresh_token",resp.data.refresh_token);
//           return axios.request(retryRequest);
//     }
//     if (
//       error.response.status === 401 &&
//       user && user.type === "startup"
//     ) {
//       const config = {
//         headers: {
//           'authorization': `Bearer ${access_token}`,
//           // 'Accept' : 'application/json',
//           //   'Content-Type': 'application/json'
//         }}
//       return await axios
//         .get("/refresh/company",config)
//         .then((resp) => {
//           return axios.request(retryRequest);
//         });
//     }
//     return Promise.reject(originalRequest.data);
//   }
// );
const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
const user = cookieData && JSON.parse(cookieData);
delete axios.defaults.headers.common["Authorization"];

// axios.defaults.headers.common['Authorization'] = `Bearer ${user && user.access_token}` 

class APIClient {
  get = (url: string, params?: object) => {
     const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
  const user = cookieData && JSON.parse(cookieData);
  const config = {
    headers: {
      authorization: `Bearer ${user.access_token}`,
      // 'Accept' : 'application/json',
      //   'Content-Type': 'application/json'
    },
  };
    return axios.get(url, params);
  };
  getWithToken = (url: string, params?: object) => {
     const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
  const user = cookieData && JSON.parse(cookieData);
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.headers.common['Authorization'] = `Bearer ${user && user.access_token}`

    return axios.get(url, params);
  };

  createWithToken = (url: string, params?: object) => {
    const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
    const user = cookieData && JSON.parse(cookieData);
    delete axios.defaults.headers.common["Authorization"];
    const config = {
      headers: {
        'Authorization': 'Basic MTM5NDljODA2NzBjOGQ0ZDA0NmI1Mzk2OTJhNWU0Mzc0NmY0MTQxMDoxY2RiNzk3N2FmZDVmOThiY2U5YjQzMDg2NThlZTA2OTY0YmEzNmQ2', 
      },
  }
      return axios.post(url, params, config);
  };

  create = (url: string, params?: object) => {
    return axios.post(url, params);
  };

  update = (url: string, params?: object) => {
    return axios.put(url, params);
  };
  delete = (url: string) => {
    const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
    const user = cookieData && JSON.parse(cookieData);
    delete axios.defaults.headers.common["Authorization"];

    const config = {
      headers: {
        authorization: `Bearer ${user.access_token}`,
        // 'Accept' : 'application/json',
        //   'Content-Type': 'application/json'
      },
    };
    return axios.delete(url,config);
  };
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      Array.isArray(data[k])
        ? formData.append(k, JSON.stringify(data[k]))
        : formData.append(k, data[k]);
    }
 
    return axios.post(url, formData);
  };
}

export default APIClient;
