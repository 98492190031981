import React from "react";
interface ItemProps{
    links:any;
    title:any;
}
const Item = ({ links, title }:ItemProps ) => {
  return (
    <ul>
      <h1 className="mb-1 px-16  font-primary color-primary font-bold font-size-16">{title}</h1>
      {links.map((link:any) => (
        <li key={link.name} className="flex center-div">
          {
            link.link ? 
            <a
            className="hover:text-primary duration-300
          text-sm cursor-pointer leading-6  font-size-14 font-primary color-black"
            href={link.link}
          >
            {link.name}
          </a>
          :
          <div
          className="
        text-sm  leading-6  font-size-14 font-primary color-black"
        >
          {link.name}
        </div>
          }
         
        </li>
      ))}
    </ul>
  );
};

export default Item;