import APIClient from "./api";
import * as url from "./urls";

const api = new APIClient();



const getNews = (params:any) => {
    return api.getWithToken(`${url.NEWS}/${params.id}`)
  }
  
const getMatches = (params:any) => {
    return api.getWithToken(`${url.MATCHES}/${params.id}`)
  }
const getSearch = (params:any) => {
    return api.getWithToken(`/search/${params.id}?count=${params.count}`)
  }
const postFirmDetails = (params:any) => {
    return api.createWithToken(`/firm_details/${params.id}`,params.data)
  }
const getContactMatches = (params:any) => {
    return api.getWithToken(`/search_contacts/${params.id}`)
  }
  const postContactsHubspot = (params:any) => {
    return api.createWithToken(`/add_contacts/${params.id}`,params.data)
  }
  const postSettings = (params:any) => {
    return api.createWithToken(`/settings/${params.id}`,params.data)
  }
  const postSettingsProfile = (params:any) => {
    return api.createWithFile(`/settings/profile/${params.id}`,params.data)
  }
  const getSettingsProfile = (params:any) => {
    return api.getWithToken(`/settings/profile/${params.id}`,params.data)
  }

  export{
    getNews,
    getMatches,
    getSearch,
    postFirmDetails,
    getContactMatches,
    postContactsHubspot,
    postSettings,
    postSettingsProfile,
    getSettingsProfile
  }