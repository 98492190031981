import React from 'react';
import PopupBanner from '../../components/PopupBanner';

export const HeroSection = () => {
  
  return (
    <>
      <div
        id='header'
        className='relative w-12/12 flex flex-row w-full'
      >
       <img src='/banner-angelinvestor.svg' />
      </div>
      {/* <PopupBanner /> */}

    </>
  );
};
