import React from 'react';
import { useRedux } from '../../hooks';
import PopupBanner from '../../components/PopupBanner';

export const HeroSection = () => {
  const { dispatch, useAppSelector } = useRedux();
  const { activeAboutPage } = useAppSelector((state) => ({
    activeAboutPage: state.Pages.activeAboutPage,
  }));
  return (
    <>
      <div
        id='header'
        className='relative w-12/12 flex flex-row w-full'
      >
        {activeAboutPage === 'about' && <img src='/walter&donnie.svg' />}
        {activeAboutPage === 'mission' && <img src='/rocket.svg' />}
        {activeAboutPage === 'members' && <img src='/membersHero.svg' />}
      </div>
      {/* <PopupBanner /> */}

    </>
  );
};
