import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { destroyCookie, setCookie } from "nookies";
//Account Redux states
import { AuthRegisterActionTypes } from "./types";
import {
  authRegisterApiResponseSuccess,
  authRegisterApiResponseError,
  signUpData,
  linkedinSignUp,
  setLoggedUser,
} from "./actions";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../../helpers/notifications";
import {
  newsletterSubscribe as newsletterSubscribeApi,
  contactUs as contactUsApi,
  requestAnalysis as requestAnalysisApi,
 } from "../../../services";
import { 
  setActiveState, 
  getCompany as getCompanyActions,
  logoutUser as logoutUserActions,
  getIndividualInvestor as getIndividualInvestorActions, 
  setModalOpen as setModalOpenActions,
} from "../../actions";
import { toast } from "react-toastify";
import { ErrorResponse } from "@remix-run/router";


 
//Include Both Helper File with needed methods

// Is user register successfull then direct plot user in redux.


function* newsletterSubscribe({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(newsletterSubscribeApi, data);
        yield put(
      authRegisterApiResponseSuccess(
        AuthRegisterActionTypes.NEWSLETTER_SUBSCRIBE,
        response
      )
    );
    yield call(showSuccessNotification, "Subscribed with success!");
    yield put(setModalOpenActions(true))

  } catch (error: any) {
    yield put(
      authRegisterApiResponseError(AuthRegisterActionTypes.NEWSLETTER_SUBSCRIBE, error)
    );
    yield call(showErrorNotification, error.response.data);
  }
}
function* contactUs({ payload: data }: any) {
  try {
    const response: Promise<any> = yield call(contactUsApi, data);
        yield put(
      authRegisterApiResponseSuccess(
        AuthRegisterActionTypes.CONTACT_US,
        response
      )
    );
    // yield call(showSuccessNotification, "Message sent with success!");
    yield put(setModalOpenActions(true))

  } catch (error: any) {
    yield put(
      authRegisterApiResponseError(AuthRegisterActionTypes.CONTACT_US, error)
    );
    yield call(showErrorNotification, error.response.data);
  }
}





export function* watchNewsletterSubscribe() {
  yield takeEvery(AuthRegisterActionTypes.NEWSLETTER_SUBSCRIBE, newsletterSubscribe);
}


export function* watchContactUs() {
  yield takeEvery(AuthRegisterActionTypes.CONTACT_US, contactUs);
}

export function* watchRequestAnalysis() {
  yield takeEvery(AuthRegisterActionTypes.REQUEST_ANALYSIS, contactUs);
}




function* registerSaga() {
  yield all([
    fork(watchNewsletterSubscribe),
    fork(watchContactUs)
      ]);
}

export default registerSaga;

