import APIClient from "./api";
import * as url from "./urls";

const api = new APIClient();

const getNotifications = (params:any) => {
    return api.getWithToken(`${url.NOTIFICATIONS}/${params.id}`)
  }

  export{
    getNotifications,
  }