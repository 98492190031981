import { PagesActionTypes } from "./types";



export const setActiveSubstep = (activeSubStep: boolean) => {
  return {
    type: PagesActionTypes.SET_ACTIVE_SUBSTEP,
    payload: { activeSubStep },
  };
};


export const setLoader = (loader: boolean) => {
  return {
    type: PagesActionTypes.LOADER,
    payload: { loader },
  };
};


export const setLoader2 = (loader2: boolean) => {
  return {
    type: PagesActionTypes.LOADER2,
    payload: { loader2 },
  };
};


export const setActiveState = (
  activeState: 
  | "home"
  | "about"
  | "investment"
  | "careers"
  | "news"
  | "contact"

) => {
  return {
    type: PagesActionTypes.SET_ACTIVE_STATE,
    payload: { activeState },
  };
};
export const setActiveAboutPage = (
  activeAboutPage: 
  | "about"
  | "mission"
  | "members"
  | "investment"
  | "careers"
  | "konzortians"
  | "workEnviroment"
  | "diversity"
  | "traction"
  | "roadmap"

) => {
  return {
    type: PagesActionTypes.ACTIVE_ABOUT_PAGE,
    payload: { activeAboutPage },
  };
};
export const setActiveCareersPage = (
  activeCareersPage: 
  | "careers"
  | "konzortians"
  | "workEnviroment"
  | "diversity"
  | "traction"
  | "roadmap"

) => {
  return {
    type: PagesActionTypes.ACTIVE_CAREERS_PAGE,
    payload: { activeCareersPage },
  };
};





