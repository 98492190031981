import { parseCookies } from "nookies";
import APIClient from "./api";
import * as url from "./urls";

const api = new APIClient();
const { "@InvestHUB.loggedUser": cookieData } = parseCookies();
const user = cookieData && JSON.parse(cookieData);


const registerUser = (params: any) => {
  return api.create(`${url.USERS}`, params);
};


const newsletterSubscribe = (params: any) => {
  return api.createWithToken(`${url.NEWSLETTER_SUBSCRIBE}`,params.data)
}
const contactUs = (params: any) => {
  return api.createWithFile(`${url.WP_JSON}`,params.data)
}
const requestAnalysis = (params: any) => {
  return api.createWithFile("${url.WP_JSON}",params.data)
}


export { 
    registerUser,
    newsletterSubscribe,
    contactUs,
    requestAnalysis
};
