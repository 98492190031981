export const USERS = "/users";
export const LOGIN = "/login";
export const API = "/api";
export const SIGNUP = "/signup"
export const GOOGLE = "/google"
export const LINKEDIN = "/linkedin"
export const AUTH = "/auth"
export const RESEND = "/resend"
export const STARTUP = "/startup"
export const INVESTOR="/investor"
export const ADD = "/add"
export const NEWSLETTER_SUBSCRIBE="/wp-json/newsletter/v2/subscribers"
export const CONTACT_US = "/contactus"
export const COMPANY = "/company"
export const STEP = "/step"
export const INDIVIDUAL = "/individual"
export const DELETEFILE = "/deletefile"
export const NEWS = "/news"
export const MATCHES = "/match"
export const MESSAGES = "/messages"
export const SEND = "/send"
export const NOTIFICATIONS = "/notifications"

export const WP_JSON = "/wp-json/contact-form-7/v1/contact-forms/38/feedback"

