import React from 'react';
import { useRedux } from '../../hooks';
import PopupBanner from '../../components/PopupBanner';

export const HeroSection = () => {
  const { dispatch, useAppSelector } = useRedux();
  const { activeCareersPage } = useAppSelector((state) => ({
    activeCareersPage: state.Pages.activeCareersPage,
  }));
  return (
    <>
      <div
        id='header'
        className='relative w-12/12 flex flex-row w-full'
      >
        {activeCareersPage === 'careers' && <img src='/careersHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
        {activeCareersPage === 'konzortians' && <img src='/konzortiansHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
        {activeCareersPage === 'workEnviroment' && <img src='/workEnviromentHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
        {activeCareersPage === 'diversity' && <img src='/diversityHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
        {activeCareersPage === 'traction' && <img src='/tractionHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
        {activeCareersPage === 'roadmap' && <img src='/roadmapHero.svg' className="min-h-[175px] object-cover object-[-75px] sm:min-h-[250px] md:object-cover md:object-[0px] lg:min-w-full" />}
      
      </div>
      {/* <PopupBanner /> */}

    </>
  );
};
