import React from "react";
import Header from "../../components/Header";
import { HeroSection } from "./HeroSection";
import { Description } from "./Description";
import { Newsletter } from "../Home/Newsletter";
import { Footer } from "../../components/Footer";

export const About = () => {
  return <>
  <Header page="about"/>
  <HeroSection/>
  <Description/>
  <Newsletter/>
  <Footer/>

  </>
};
