import React, { useState } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineCheckCircle,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import { useRedux } from '../../hooks';
import { setActiveAboutPage, setLoader, setLoader2 } from '../../redux/actions';
import { ContactCard } from './ContactUs';
import { toast, ToastContainer } from 'react-toastify';

export const Description = () => {
  const { dispatch, useAppSelector } = useRedux();
  const { activeAboutPage, loader, loader2 } = useAppSelector((state) => ({
    activeAboutPage: state.Pages.activeAboutPage,
    loader: state.Pages.loader,
    loader2: state.Pages.loader2
  }));
  
 
  const showSuccessNotification = (message: any) => {
    toast.success(message);
  };

  const showErrorNotification = (error: any) => {
    toast.error(error);
  };

  const [form_competitor, set_form_competitor] = useState({
    name: '',
    email: '',
    phone: '',
    type: 'analysis_competitor_kc',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    set_form_competitor({ ...form_competitor, [name]: value });
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault(); // Prevent form submission
    dispatch(setLoader2(true));
    try {
      console.log(form_competitor);
      fetch('https://konzortiacapital.com/save.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form_competitor),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message == 'Data received successfully') {
            console.log('success');
            dispatch(setLoader2(false));
            showSuccessNotification(
              'Successfully submitted, We will contact you soon'
            );
            set_form_competitor({
              name: '',
              email: '',
              phone: '',
              type: 'analysis_competitor_kc',
            });
          } else {
            showErrorNotification('An error Occured, Try again later!');
            set_form_competitor({
              name: '',
              email: '',
              phone: '',
              type: 'analysis_competitor_kc',
            });
            dispatch(setLoader2(false));
          }
        });
    } catch (error) {
      dispatch(setLoader2(false));

      console.error('Error submitting form:', error);
      showErrorNotification('An error Occured, Try again later!');
    }
  };
  return (
    <>
      <div className='px-4 mx-auto container w-12/12 bg-white '>
        <div className='flex flex-col lg:flex-row '>
        <div className='lg:w-9/12 w-12/12 leading-loose pt-12 px-12 flex flex-col content-left justify-left text-left'>
            <span className='font-primary font-size-30 color-dark-blue font-bold py-4'>
                Investing for Legacy and Long-Term Growth 
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
            For family offices, ensuring long-term growth and preserving wealth through generations is a top priority.
            This requires more than traditional public market investments. 
            The truth is <b>public markets are no longer the optimal choice for building enduring wealth</b>. 
            <br/>
            The pressure to meet short-term financial targets, such as quarterly earnings reports, 
            forces public companies to prioritize short-term gains over long-term strategies. 
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
            <b>Private markets:</b> offer the chance to invest in innovative companies that are focused on the long term. But for many family offices,
            investing in private markets, especially venture capital (VC), can be daunting.
             Many lack this type of investing experience and the limited data available means it’s difficult to make informed decisions. 
            </span>
            <span className='font-primary font-size-16 color-dark-blue '>
            Furthermore,<b>we’re on the verge of the largest wealth transfer in history</b>, 
            with an estimated $84 trillion expected to be passed down to younger generations over the next two decades.
             As this shift takes place, younger family office members will need powerful investment tools to maintain and grow their family’s legacy. 
            </span>
            <div className=" flex flex-col justify-center items-center pt-10" >
              <div className="justify-center items-center text-center" >
                  <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                      How it works
                  </h2>
                  <div className="color-white pt-8 lg:pt-16 mt-4 lg:mt-8 mx-auto pb-8 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-2">
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }} >
                          <h3 className="font-bold font-size-16">Personalized Investment Criteria</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                          Start by creating a custom profile based on the investment filters that matter most to you—industry, location, revenue type, valuation, and more. 
                          </p>
                      </div>
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }}>
                          <h3 className="font-bold mt-2 mb-1 text-lg">Automated Deal Sourcing</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                          Our Smart Recommendation Engine uses cutting-edge AI algorithms to search our extensive, 
                          real-time database of private companies, matching your criteria with those of the companies for the best alignment. 
                          </p>
                      </div>
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }}>
                          <h3 className="font-bold mt-2 mb-1 text-lg">Data-Driven Evaluation</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                          When it comes to evaluating a deal, Konzortia Hub uses industry data, benchmarking tools, and AI-driven analysis. 
                          What would normally take weeks for a team of analysts, our AI can do in minutes—transforming textual data into actionable insights.
                          </p>
                      </div>
                      <div className="flex flex-col items-center p-4 lg:p-10 lg:text-left rounded-lg" style={{ backgroundColor:'#2698C6' }}>
                          <h3 className="font-bold mt-2 mb-1 text-lg">Seamless Deal Execution</h3>
                          <p className="text-center lg:text-left text-sm max-w-[400px]">
                          From contract signing to disbursing funds, Konzortia Hub streamlines every step of the deal-making process. 
                          Our decentralized database ensures transparency, reduces fraud risks, and boosts efficiency. 
                          </p>
                      </div>
                  </div>
              </div>
            </div>
            <div className=" flex flex-col justify-center items-center pt-10" >
                <div className='flex flex-col py-2 pt-4'>
                    <YoutubeEmbed embedId='9AQWEMO7nkM' width="600px" height="350px" />
                </div>
            </div>
            <div className=" flex flex-col justify-center items-center pt-10" >
              <div className="justify-center items-center text-center" >
                <h2 className="font-primary font-size-30 color-dark-blue font-bold">
                    Join The Founder&apos;s Circle
                </h2>
                <span className='font-primary font-size-16 color-dark-blue '>Want to experience the benefits of Konzortia Hub firsthand? By joining our Founder&apos;s Circle, you can help shape the future of private capital markets. Your input will be key in refining the platform to better serve family offices like yours.</span>
                <div className='flex flex-col pt-24'>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Sourcing and Matching Deals (AI/ML): </b>
                        Leveraging advanced AI and ML algorithms to optimize deal sourcing and
                      matching.
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Free Access: </b>
                      1 year of free access to the platform after the beta phase ends.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Premium Support: </b> 
                      Priority assistance and expert troubleshooting throughout the beta testing phase.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' />
                    </div>
                    <span className='font-primary color-dark-blue text-left'>
                      <b>Recognition: </b>
                      Your firm can be featured in Konzortia&apos;s Hub marketing material.{' '}
                    </span>
                  </div>
                  <div className='flex flex-row py-4'>
                    <div className='px-1'>
                      <img src='/checkmark.svg' className='fitwidth' />
                    </div>

                    <span className='font-primary color-dark-blue text-left'>
                      <b>Networking Opportunities</b> 
                      Connect with other beta testers and join our advisory board or ongoing user group.{' '}
                    </span>
                </div>
                <span className='font-primary font-size-16 color-dark-blue pt-10'>
                  To discuss the program, schedule a meeting with me <a  className="font-primary font-bold" style={{ color: '#2698C6' }} href="https://calendly.com/walter-gomez-kc/founders-circle-meeting?month=2024-09">here</a>.  
                  <br/>
                  As the great wealth transfer approaches, ensuring access to the best private market opportunities will be critical for securing your family&apos;s legacy. 
                  <b>Let&apos;s build the future of private investing, together</b>.
                </span>  
              </div>
            </div>
          </div>
            
        </div>

          <div className='py-12 w-12/12 lg:w-3/12'>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR ONEPAGER
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/onepage.png' />
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/65ytphyfnb4ymzdh')
                  }
                >
                  Onepager
                </button>
              </div>
            </div>
            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/video1.png' />
              </div>
            </div>
            <ContactCard />
            <div className='flex  py-10 px-2 w-12/12 '>
              <div className='social-media-card w-100 h-100'>
                <div className='flex flex-row w-12/12 h-100 center-div'>
                  <span className='font-secondary-italic text-white font-size-24'>
                    Follow us!
                  </span>
                  <div className='px-2 color-white'>
                    <a href="https://x.com/konzortia_c"><AiOutlineTwitter size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.facebook.com/konzortia"><AiFillFacebook size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.linkedin.com/company/konzortiacapital/"><AiFillLinkedin size={20} /></a>
                  </div>
                  <div className='px-2 color-white'>
                    <a href="https://www.instagram.com/konzortia_capital/"><AiOutlineInstagram size={20} /></a>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem', backgroundColor: '#F4F4F4' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-bold font-size-22 color-dark-blue'>
                  REVIEW OUR PITCH DECK
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/investhubpc.png' />
              </div>
              <div style={{ paddingTop: '1.5rem' }}>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-16'
                  style={{ width: '150px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://docsend.com/view/n5h9qf9rbjx9epsh')
                  }
                >
                  Pitch Deck
                </button>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary font-size-22 color-dark-blue'>
                  Request Access to our <b>Competitive Analysis</b>
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/competitiveanalysis.png' />
              </div>
              <div
                style={{
                  paddingTop: '1.5rem',
                  paddingBottom: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <form className='w-3/4'>
                  <div className='w-12/12'>
                    <div className='flex flex-col'>
                      <div className='flex flex-col'>
                        <label
                          htmlFor='name'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Name
                        </label>{' '}
                        <input
                          type='text'
                          name='name'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                      <div className='flex flex-col py-2'>
                        <label
                          htmlFor='email'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Email
                        </label>{' '}
                        <input
                          type='text'
                          name='email'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                      <div className='flex flex-col '>
                        <label
                          htmlFor='phone'
                          className='font-primary font-size-12 color-dark-blue'
                          style={{ textAlign: 'left' }}
                        >
                          Phone
                        </label>{' '}
                        <input
                          type='text'
                          name='phone'
                          onChange={handleChange}
                          className='form-control w-100 font-primary font-size-12 '
                          style={{ marginTop: '0px' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '1.5rem' }} className="flex center-div content-center justify-center">
                    <button
                      className='btn-primary font-primary font-bold color-white font-size-16 flex justify-center content-center'
                      style={{ width: '150px' }}
                      onClick={handleSubmit}
                      disabled={loader2}
                    >
                      {' '}
                      {loader2 ? (
                        <div className='mt-2 spinner'></div>
                      ) : (
                        <span className='mt-1'>Request</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <div style={{ paddingTop: '2.5rem' }}>
              <div
                className='flex flex-row'
                style={{ justifyContent: 'center' }}
              >
                <span className='font-primary  font-size-22 color-dark-blue'>
                  <i>Request access</i>
                  <br />
                  TO OUR DATA ROOM
                </span>
              </div>
              <div
                className='flex flex-row '
                style={{ justifyContent: 'center' }}
              >
                <img src='/dataroom.png' />
              </div>
              <div>
                <button
                  className='btn-primary font-primary font-bold color-white font-size-12'
                  style={{ width: '174px' }}
                  onClick={() =>
                    (window.location.href =
                      'https://dealroom.konzortiacapital.com/')
                  }
                >
                  Data Room
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    </>
  );
};
